<template>
    <div class="whole" style="">
        <div class="bgwhit" >
            <div class="title allhet">
                <div class="tileft allhet"></div>
                <div class="titext allhet">公司概况</div>
            </div>
            <div class="tibottom"></div>
            <div class="contentProject">
                <div  v-html="aboutUs"></div>
            </div>


            <div class="title allhet">
                <div class="tileft allhet"></div>
                <div class="titext allhet">联系我们</div>
            </div>
            <div class="tibottom"></div>
            <div class="content">
                <div style="padding:10px;">单位名称：北京睿易博达科技有限公司</div>
                <div style="padding:10px;">单位地址：北京市海淀区北洼西里北京市气象局科技楼</div>
                <!--<div style="padding:10px;">商务部门：郑昌燕：15110086342    68400770/71-8060-->
                <!--<p style="white-space:pre;margin-left: 95px;padding:10px;">陈     铮：13910398155</p>-->
            <!--</div>-->
                <img style="padding-left: 10px" src="~assets/img/phone.png">
            </div>
        </div>
    </div>


</template>

<script>
    export default {
        data(){
            return{
                aboutUs: "<p><br/></p><p style=\"text-indent: 43px; line-height: 2em;\">\n" +
                    "    <span style=\"font-size:21px;line-height:150%;font-family:仿宋_GB2312\">北京睿易博达科技有限公司属于北京市气象局直属气象科技产业公司，2002年成立，注册资金为3000万元。具有高新技术企业、GB/T19001质量管理体系、ITSS信息技术服务运行维护标准、ISO14001环境管理体系认证、信息安全管理体系认证证书、信息系统建设和服务能力等级证书（CS2）、软件著作权30多个、北京市气象信息服务备案等证书。完成了北京市政府、北京市路网分中心、北京市应急管理局、北京市水务局、北京市南水北调信息中心、大兴区应急管理局、北京市排水集团、北京市公交集团、平谷区气象局等单位的专业气象服务。与中核清原环境技术工程有限责任公司、中科软科技股份有限公司、北京航空航天大学等单位合同，提供专业气象服务产品。承担并完成了甘肃省、北京市的气象灾害风险普查项目。在系统集成及社会化运维保障服务方面，完成了北京市气象局气象综合探测系统委托服务采购项目、水利工程日常维修养护-2023年自动化系统运行维护项目、幼儿园运行保障经费-2023年信息化系统运行维护项目、信息系统运维类项目-信息化运行维护等项目。</span>\n" +
                    "</p>\n" +
                    "<p style=\"text-indent: 43px; line-height: 2em;\">\n" +
                    "    <span style=\"font-size:21px;line-height:150%;font-family:仿宋_GB2312\">公司业务范围为依托北京市气象局气象观测数据（高空、地面、辐射、农业气象、大气化学、气象灾害、土壤、雷达、卫星等）、预报数据、气候资料、气候评价等大数据资源，进行专业气象信息服务、软件系统开发等，同时从事系统集成相关工作以及软件系统、网络平台、视频会议、视频监控等信息系统的社会化运维保障服务。</span>\n" +
                    "</p>\n" +
                    "<p style=\"line-height: 2em;\">\n" +
                    "    <br/>\n" +
                    "</p>"
            }
        },
        methods:{

        },
        mounted(){

        }
    }
</script>

<style  lang="scss"  scoped>
    .whole{
        width:90%;margin: 0 auto;
        height: auto;padding: 15px 0 2px 0;
    }
    .allhet{
        height: 40px;
    }
    .title{
        width:100%;margin-top:5px;
        font-size: 23px;
    }
    .tileft{
        float:left;width:6px;
        background: #779DBF;
    }
    .titext{
        line-height: 38px;
        background:#779DBF;
        color:#ffe100;
        float:left;width:99%;margin-left:5px;font-weight: 800;
    }
    .tibottom{
        width:100%;height: 3px;;margin-top:3px;
        background: #779DBF;
        /*background:#0077b2*/
    }
    .bgwhit{
        width: 100%;
        /*background: #FFFFFF;*/
        border-radius: 5px ;
        /*border: 2px solid  #d4e8f9;*/
    }
    .contentProject{
        margin: 8px;
        padding: 0 10px;
        border: 2px solid #d2cdcd;
        height: auto;
    }

    .content{
        font-size: 21px;
        margin: 8px;
        border: 2px solid #d2cdcd;
        height: auto;
    }

</style>
