<template>
    <div class="footer-box" :style="{background:'#06090e'}" >
        <!--:style="{backgroundImage:'url('+require('assets/img/title.png')+')'}"-->
        <!--:style="{background:'#06090e'}"-->
        <div class="top">
            <span>版权所有</span>
            <span>复制必究</span>
            <span>未经书面授权禁止使用</span>
            <a href="https://beian.miit.gov.cn/" target="_blank" style="cursor:pointer;color:#ffffff">京ICP备19041300号</a>
        </div>
        <div class="bottom">
            <span>主办单位和技术支持单位：北京睿易博达科技有限公司 (010) 68400770/71-8030/8053</span>
        </div>
    </div>
</template>

<script>
    export default {};
</script>

<style lang="scss">
    .footer-box {
        width: 100%;
        height: 60px;
        margin-bottom:15px;
        font-size: 14px;
        text-align: center;
        color: #ffffff;
        .top {
            width: 100%;
            padding-top: 12px;
            text-align: center;
            span {
                display: inline-block;
                padding-right: 5px;
            }
        }
        .bottom{
            margin-top:5px;
            span {
                padding-right: 20px;
        }
        }
    }
</style>
